<template>
  <div class="main">
    <div class="wrapper">
      <h1 class="display-2 font-weight-normal text-center mt-5">Thank you!</h1>
      <h4 class="font-weight-normal text-center">Verification link is sent, please verify email before login.</h4>
      <h4 class="font-weight-normal text-center">Click <router-link :to="{ name: 'login', params: {} }">here</router-link> to login.</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'verify',
  props: [],
  components: {},
  data () {
    return {

    }
  },
  methods: {

  },
  created () {

  },
  mounted () {

  },
  destroy () {

  }
}
</script>
